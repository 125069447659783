<template>
    <div>
        <div class="position-relative">
            <img
                src="@/assets/img/category_list/category_list_page.png"
                alt="header image"
                class="w-100 d-none d-lg-block"
            />
            <h1 class="title-center" style="top: 50%;">
                {{ $route.query.category_name || '' }}
            </h1>
        </div>

        <div class="container py-5">
            <div
                v-if="windowWidth >= 992"
                class="d-flex justify-content-between align-items-center gap-4"
            >
                <div class="d-flex align-items-center gap-4">
                    <vue-horizontal
                        v-if="
                            menuLinks &&
                                ($route.query.category_id ||
                                    $route.query.name === 'newArrivals' ||
                                    $route.query.name === 'trendingProducts')
                        "
                        responsive
                        class="sub-list-style"
                        :displacement="0.5"
                        snap="center"
                    >
                        <!-- <template v-slot:btn-prev>
                            <div class="replaced-btn cursor">
                                <font-awesome-icon
                                    @click="prevMenu"
                                    :icon="['fas', 'chevron-left']"
                                />
                            </div>
                        </template>
                        <template v-slot:btn-next>
                            <div class="replaced-btn cursor">
                                <font-awesome-icon
                                    @click="nextMenu"
                                    :icon="['fas', 'chevron-right']"
                                />
                            </div>
                        </template> -->
                        <div
                            style="width: max-content;font-weight: bold;"
                            class="list-unstyled sub-title"
                            :class="[activeFilter('')]"
                            @click="allJewellery"
                        >
                            All
                            <span v-if="$route.query.category_name">{{
                                $route.query.category_name
                            }}</span>
                            <span v-else>jewellery</span>
                        </div>
                        <div
                            style="width: max-content;font-weight: bold;"
                            class="list-unstyled sub-title"
                            v-for="(category, index) in menuLinks"
                            :key="index"
                        >
                            <span v-if="!$route.query.name">
                                <span
                                    v-if="sub_category_filter == category.id"
                                    :class="[activeFilter(category.id)]"
                                    >{{ category.name }}</span
                                >
                                <span
                                    v-else
                                    @click="
                                        () =>
                                            (sub_category_filter = category.id)
                                    "
                                    >{{ category.name }}</span
                                >
                            </span>
                            <span v-else>
                                <span
                                    v-if="category_filter == category.id"
                                    :class="[activeFilter(category.id)]"
                                    >{{ category.name }}</span
                                >
                                <span
                                    v-else
                                    @click="
                                        () => (category_filter = category.id)
                                    "
                                    >{{ category.name }}</span
                                >
                            </span>
                        </div>
                    </vue-horizontal>

                    <ul class="sub-list-style" v-else>
                        <li class="list-unstyled sub-title">
                            <span v-if="$route.query.collection_name">{{
                                $route.query.collection_name
                            }}</span>
                            <span v-else>No sub-categories found!</span>
                        </li>
                    </ul>
                    <!-- <div class="cursor">
                        <font-awesome-icon
                            @click="nextMenu"
                            :icon="['fas', 'chevron-right']"
                        />
                    </div> -->
                </div>
                <!-- <div>
                    <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </div> -->
                <div
                    class="d-flex gap-2 mt-xs-2 justify-content-center justify-content-lg-start"
                >
                    <div
                        @click="toWishlist"
                        style="border:1px solid black;border-radius:8px;padding:0.3rem;cursor:pointer;display:flex;align-items: center"
                    >
                        <heart-icon /> Wishlist
                    </div>
                    <div
                        @click="showWebFilter = true"
                        style="border:1px solid black;border-radius:8px;padding:0.3rem;cursor:pointer;display:flex;align-items: center"
                    >
                        <font-awesome-icon
                            :icon="['fas', 'sort-amount-down']"
                        />
                        Filters
                    </div>
                </div>
            </div>
            <div
                v-else
                class="d-flex justify-space-between align-items-center gap-2"
                :class="$route.query.collection_name ? 'flex-column' : ''"
            >
                <div
                    class="aselect flex-grow-1"
                    :data-value="selectedSubCat"
                    :data-list="menuLinks"
                    v-if="
                        $route.query.category_id ||
                            $route.query.name === 'newArrivals' ||
                            $route.query.name === 'trendingProducts'
                    "
                >
                    <div class="selector" @click="toggle()">
                        <div class="label">
                            <span>{{ selectedSubCat }}</span>
                        </div>
                        <div
                            class="arrow"
                            :class="{ expanded: dropdownVisible }"
                        ></div>
                        <div
                            :class="{
                                hidden: !dropdownVisible,
                                dropdownVisible,
                            }"
                        >
                            <ul>
                                <li
                                    class="d-flex align-items-center me-2"
                                    :class="{
                                        current:
                                            category.name === selectedSubCat,
                                    }"
                                    v-for="(category, index) in menuLinks"
                                    :key="index"
                                    @click="select(category)"
                                >
                                    <input
                                        type="radio"
                                        style="accent-color: green;margin-right: 0.5rem"
                                        :checked="
                                            category.name === selectedSubCat
                                        "
                                    />
                                    {{ category.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ul class="sub-list-style d-flex flex-grow-1" v-else>
                    <li class="list-unstyled text-center">
                        <span v-if="$route.query.collection_name">{{
                            $route.query.collection_name
                        }}</span>
                        <span v-else>No sub-categories found!</span>
                    </li>
                </ul>
                <div class="d-flex gap-2">
                    <div
                        class="btn-sm-outline"
                        style="border:1px solid gainsboro;border-radius:5px;padding:0.3rem;cursor:pointer"
                        @click="toWishlist"
                    >
                        <heart-icon />
                    </div>
                    <div
                        class="btn-sm-outline"
                        style="border:1px solid gainsboro;border-radius:5px;padding:0.3rem;cursor:pointer"
                        @click="showWebFilter = true"
                    >
                        <font-awesome-icon
                            :icon="['fas', 'sort-amount-down']"
                        />
                    </div>
                </div>
            </div>
            <div id="filterBox">
                <transition
                    @enter="startTransitionModal"
                    @after-enter="endTransitionModal"
                    @before-leave="endTransitionModal"
                    @after-leave="startTransitionModal"
                >
                    <div
                        class="modal fade"
                        style="background: #0000001A;"
                        v-show="showWebFilter"
                        ref="modal"
                        @click.self="showWebFilter = false"
                    >
                        <div
                            class="modal-dialog modal-sm modal-dialog-centered"
                        >
                            <div class="modal-content">
                                <div class="modal-header border-0 mt-2 p-1">
                                    <!-- <a
                                            href="javascript:void(0)"
                                            class="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            @click="showWebFilter = false"
                                        ></a> -->
                                </div>
                                <div class="modal-body">
                                    <div
                                        class="d-flex flex-column align-items-start justify-content-start"
                                    >
                                        <div class="filterType">
                                            <h6 class="fw-bold">
                                                Gold Type
                                            </h6>
                                            <ul class="list-unstyled">
                                                <li
                                                    class="filterType__item ms-2"
                                                    v-for="(gold_type,
                                                    index) in goldTypes"
                                                    :key="index"
                                                >
                                                    <input
                                                        :id="
                                                            `filterGoldType${index}`
                                                        "
                                                        class="form-check-input me-2"
                                                        type="checkbox"
                                                        name="filterPrice"
                                                        v-model="
                                                            gold_type_filter
                                                        "
                                                        :value="gold_type.id"
                                                    />
                                                    <label
                                                        :for="
                                                            `filterGoldType${index}`
                                                        "
                                                    >
                                                        {{ gold_type.name }}
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <!-- pricing_filter -->
                                        <div
                                            class="filterType"
                                            v-if="
                                                pricingGroups &&
                                                    pricingGroups.length > 0
                                            "
                                        >
                                            <h6 class="fw-bold">
                                                Price Range
                                            </h6>
                                            <ul class="list-unstyled">
                                                <li class="filterType__item">
                                                    <input
                                                        id="
                                                                `filterPriceMenu-0`
                                                            "
                                                        class="form-check-input me-2"
                                                        type="radio"
                                                        name="filterPrice"
                                                        v-model="pricing_filter"
                                                        value=""
                                                    />
                                                    <label
                                                        for="
                                                                `filterPriceMenu$-0`
                                                            "
                                                    >
                                                        Default
                                                    </label>
                                                </li>
                                                <li
                                                    class="filterType__item"
                                                    v-for="(pc,
                                                    index) in pricingGroups"
                                                    :key="index"
                                                >
                                                    <input
                                                        :id="
                                                            `filterPriceMenu${pc.id}`
                                                        "
                                                        class="form-check-input me-2"
                                                        type="radio"
                                                        name="filterPrice"
                                                        v-model="pricing_filter"
                                                        :value="pc.value"
                                                    />
                                                    <label
                                                        :for="
                                                            `filterPriceMenu${pc.id}`
                                                        "
                                                    >
                                                        {{ pc.label }}
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        class="d-flex align-items-center justify-content-between py-2 border-top"
                                    >
                                        <button
                                            style="background:#fff; border:none"
                                            href="javascript:void(0)"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            @click="filteringProducts(false)"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            @click="filteringProducts(true)"
                                            style="background: #F6F6F6 0% 0% no-repeat padding-box;border-radius: 30px;opacity: 1;border:none;padding:0.2rem 1rem;font-weight:bold"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="modal-backdrop fade d-none" ref="backdrop"></div>
            </div>
            <div
                class="d-flex flex-wrap justify-content-center justify-content-md-start mt-4 pb-4 pt-2"
                style="gap:1rem"
                v-if="products"
            >
                <div
                    class="card"
                    v-for="(product, index) in products.data"
                    :key="index"
                >
                    <div>
                        <img
                            :src="product.photo.thumbnail_url"
                            :alt="product.title"
                            class="img-fluid cursor"
                            @click="toDetailPage(product.slug)"
                        />
                        <font-awesome-icon
                            v-show="isLoggedIn"
                            @click="toggleWish(product.id)"
                            :icon="['fas', 'heart']"
                            class="icon position-absolute rounded-circle bg-light"
                            :class="{
                                'wish-list': hasInWishList(product.id),
                            }"
                            :id="`active-wish-${product.id}`"
                        />
                        <div
                            class="my-2 mx-2"
                            @click="toDetailPage(product.slug)"
                        >
                            <p class="arrival-body" style="font-size: 14px;">
                                {{ product.title }}
                            </p>
                            <div class="d-flex flex-column flex-lg-row gap-2">
                                <!-- <p
                                    v-if="
                                        product.productvariants[0]
                                            .discounted_price
                                    "
                                    class="arrival-body fw-bold"
                                >
                                    {{
                                        addCommaForPrice(
                                            product.productvariants[0]
                                                .discounted_price,
                                        )
                                    }}
                                    MMK
                                </p> -->
                                <p
                                    v-if="discountedVariant(product)"
                                    class="arrival-body fw-bold"
                                >
                                    {{
                                        addCommaForPrice(
                                            discountedVariant(product)
                                                .discounted_price,
                                        )
                                    }}
                                    MMK
                                </p>
                                <p
                                    class="sold-out text-center"
                                    v-if="
                                        soldOutedProducts.includes(product.id)
                                    "
                                >
                                    Out of Stock
                                </p>
                            </div>
                            <div class="d-flex flex-column flex-lg-row gap-1">
                                <!-- <p
                                    class="arrival-body"
                                    :class="
                                        product.productvariants[0]
                                            .discounted_price
                                            ? 'text-decoration-line-through'
                                            : 'fw-bold fs-6'
                                    "
                                    style="font-size: 14px;"
                                >
                                    {{
                                        addCommaForPrice(
                                            product.productvariants[0]
                                                .final_price,
                                        )
                                    }}
                                    MMK
                                </p> -->
                                <p
                                    class="arrival-body"
                                    :class="
                                        discountedVariant(product)
                                            ? 'text-decoration-line-through'
                                            : 'fw-bold fs-6'
                                    "
                                    style="font-size: 14px;"
                                >
                                    {{
                                        addCommaForPrice(
                                            originalVariantPrice(product),
                                        )
                                    }}MMK
                                </p>
                                <!-- <p
                                    class="arrival-body"
                                    style="font-size: 14px;"
                                    v-if="
                                        product.productvariants[0]
                                            .discounted_type != 0
                                    "
                                >
                                    {{
                                        product.productvariants[0]
                                            .discounted_amount
                                    }}
                                    {{
                                        product.productvariants[0]
                                            .discounted_type == 1
                                            ? '% OFF'
                                            : 'MMK OFF'
                                    }}
                                </p> -->
                                <p
                                    class="arrival-body"
                                    style="font-size: 14px;"
                                    v-if="discountedVariant(product)"
                                >
                                    {{
                                        discountedVariant(product)
                                            .discounted_amount
                                    }}
                                    {{
                                        discountedVariant(product)
                                            .discounted_type == 1
                                            ? '% OFF'
                                            : 'MMK OFF'
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!products" class="text-center">
                <div
                    class="spinner-border"
                    style="width: 3rem; height: 3rem"
                    role="status"
                >
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <!-- show more button -->
            <div class="text-center mb-5" v-if="products">
                <p>{{ current_count }}/{{ total_count }}</p>
                <button v-if="hasMorePages" class="btn" @click="showMore">
                    Show More
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import Button from '../components/reusable/Button.vue';
import Items from '@/mixins/Items';
import CommaPrice from '@/mixins/CommaPrice';
import AddToWishList from '@/mixins/AddToWishlist';
import encryptDecrypt from '@/mixins/PromotionEncryptDecrypt';

// apollo query
import Products from '@/graphql/queries/product/Products.gql';
import trendingCategories from '@/graphql/queries/product/TrendingCategories.gql';
// import NewArrivalProducts from '@/graphql/queries/home/NewArrivalProducts.gql';
// import TrendingProducts from '@/graphql/queries/product/TrendingProducts.gql';

// import PriceRage from '@/graphql/queries/product/PriceRange.gql';
import PricingGroup from '@/graphql/queries/product/PricingGroup.gql';
import SubCategories from '@/graphql/queries/product/SubCategories.gql';

export default {
    mixins: [Items, CommaPrice, AddToWishList, encryptDecrypt],

    data() {
        return {
            filtered_count: 0,
            current_page: 1,
            hasMorePages: false,
            current_count: 0,
            total_count: 0,
            showWebFilter: false,
            soldOutedProducts: [],
            pricingGroups: null,
            category_filter: '',
            sub_category_filter: '',
            pricing_filter: '',
            gold_type_filter: [],
            windowWidth: window.innerWidth,
            selectedSubCat: '',
            dropdownVisible: false,
            isNewArrival: false,
            trending_categories: [],
            prevCategory: '',
            nextCategory: '',
            menuIndex: 0,
        };
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
        if (this.$route.query.name) {
            this.selectedSubCat = 'All jewellery';
        }
        if (this.$route.query.name === 'newArrivals') {
            this.isNewArrival = true;
        }
        // if (this.$route.params.name === 'newArrivals') {
        //     this.getNewArrivalProducts();
        // } else if (this.$route.params.name === 'trendingProducts') {
        //     this.getTrendingProducts();
        // } else {
        //     this.getProducts();
        // }
        this.getProducts();
        this.getTrendingCategories();
        this.getSubCategories();
        this.getPricingGroups();
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    computed: {
        ...mapGetters({
            subCategories: 'product/getSubCategories',
            categories: 'home/getCategories',
            products: 'product/getProducts',
            isLoggedIn: 'auth/isLoggedIn',
            goldTypes: 'home/getAllGoldTypes',
        }),
        productVariables() {
            let variable = {};
            if (this.$route.query.collection_id) {
                variable.collection_id = this.decryptAES(
                    this.$route.query.collection_id,
                    'pss',
                );
            }
            if (this.$route.query.name === 'trendingProducts') {
                variable.is_trending = true;
            }
            if (this.$route.query.category_id && !this.sub_category_filter) {
                variable.category_id = this.decryptAES(
                    this.$route.query.category_id,
                    'pss',
                );
            }
            if (this.category_filter) {
                variable.category_id = this.category_filter;
            }
            if (this.sub_category_filter) {
                variable.sub_category_id = this.sub_category_filter;
            }
            if (this.pricing_filter) {
                variable.min_price = this.pricing_filter.min_price;
                variable.max_price = this.pricing_filter.max_price;
            }
            if (this.gold_type_filter.length) {
                variable.gold_type_ids = this.gold_type_filter;
            }
            return variable;
        },
        subCategoriesVariables() {
            if (this.$route.query.category_id) {
                return {
                    query: SubCategories,
                    variables: {
                        category_id: this.decryptAES(
                            this.$route.query.category_id,
                            'pss',
                        ),
                    },
                };
            } else {
                return {
                    query: SubCategories,
                };
            }
        },
        menuLinks() {
            let menu;
            if (this.$route.query.name === 'newArrivals') {
                menu = this.categories;
            } else if (this.$route.query.name === 'trendingProducts') {
                menu = this.trending_categories;
            } else {
                menu = this.subCategories;
            }
            return menu;
        },

        // filter() {
        //     if (
        //         this.$route.query.name === 'newArrivals' ||
        //         this.$route.query.name === 'trendingProducts'
        //     ) {
        //         return this.category_filter;
        //     } else {
        //        return this.sub_category_filter;
        //     }
        // }
    },
    watch: {
        '$route.query.category_id': {
            handler() {
                this.getProducts();
            },
        },
        '$route.query.collection_id': {
            handler() {
                this.getProducts();
            },
        },
        sub_category_filter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getProducts();
            }
        },
        '$route.query': {
            handler(newVal) {
                if (newVal.category_name) {
                    this.selectedSubCat = `All ${newVal.category_name}`;
                }
                if (newVal.collection_id || newVal.category_id) {
                    this.getSubCategories();
                }
            },
            deep: true,
            immediate: true,
        },
        category_filter(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.getProducts();
            }
        },
    },
    methods: {
        originalVariantPrice(prd) {
            return (
                prd.productvariants.find(
                    pv => pv.total_in_stock >= 0 && pv.discounted_type !== 0,
                )?.final_price ??
                prd.productvariants.find(pv => pv.total_in_stock > 0)
                    ?.final_price ??
                prd.productvariants[0]?.final_price
            );
        },
        discountedVariant(prd) {
            return (
                prd.productvariants.find(pv => {
                    let pro = pv.latestPromotion;
                    // console.log(pv);
                    if (pro) {
                        if (
                            pro.status === 'active' &&
                            new Date(pro.start_date) <= new Date() &&
                            new Date(pro.end_date) >= new Date()
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return pv.discounted_type != 0;
                    }
                }) || null
            );
        },
        onResize() {
            this.windowWidth = window.innerWidth;
        },
        allJewellery() {
            (this.category_filter = ''), (this.sub_category_filter = '');
            this.sub_category_id = '';
            this.getProducts();
        },
        filteringProducts(isFiltering) {
            if (!isFiltering) {
                this.sub_category_filter = '';
                this.gold_type_filter = [];
                this.pricing_filter = '';
            }
            this.getProducts();
            this.showWebFilter = false;
        },
        getTrendingCategories() {
            this.$apollo
                .query({
                    query: trendingCategories,
                    fetchPolicy: 'no-cache',
                    variables: {
                        is_trending: true,
                    },
                })
                .then(response => {
                    // console.log(response.data.trendingCategories);
                    this.trending_categories = response.data.trendingCategories;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getSubCategories() {
            this.$apollo
                .query(this.subCategoriesVariables)
                .then(response => {
                    this.$store.commit(
                        'product/ADD_SUB_CATEGORIES',
                        response.data.subCategories,
                    );
                })
                .catch(error => {
                    console.log(error.response);
                });
        },
        getPricingGroups() {
            this.$apollo
                .query({
                    query: PricingGroup,
                })
                .then(response => {
                    this.pricingGroups = response.data.pricinggroup;
                    this.pricingGroups.map(gp => {
                        gp.value = {
                            min_price: gp.min_price,
                            max_price: gp.max_price,
                        };
                        switch (gp.id) {
                            case 1:
                                gp.label = `Below ${
                                    String(gp.max_price)[0]
                                }Lakh`;
                                break;
                            case 2:
                                gp.label = `Between ${
                                    String(gp.min_price)[0]
                                }Lakh-${String(gp.max_price).slice(0, 2)}Lakh`;
                                break;
                            case 3:
                                gp.label = `Between ${String(
                                    gp.min_price,
                                ).slice(0, 2)}Lakh-${String(gp.max_price).slice(
                                    0,
                                    3,
                                )}Lakh`;
                                break;
                            case 4:
                                gp.label = `Upper ${String(gp.min_price).slice(
                                    0,
                                    3,
                                )}Lakh`;
                                break;
                        }
                    });
                })
                .catch(errors => {
                    console.log(errors);
                });
        },
        toWishlist() {
            if (this.isLoggedIn) {
                this.$router.push({
                    name: 'WishList',
                });
            } else {
                this.$router.push({
                    name: 'Login',
                });
            }
        },
        activeFilter(item) {
            if (!this.$route.query.name) {
                return this.sub_category_filter == item
                    ? 'active-category'
                    : '';
            } else {
                return this.category_filter == item ? 'active-category' : '';
            }
        },
        // getPrices() {
        //     this.$apollo
        //         .query({
        //             query: PriceRage,
        //         })
        //         .then(response => {
        //             this.min_price = response.data.productpricing.min_price;
        //             this.max_price = response.data.productpricing.max_price;

        //             this.default_min_price = this.min_price;
        //             this.default_max_price = this.max_price;

        //             this.getProducts();
        //         })
        //         .catch(error => {
        //             console.log(error.response);
        //         });
        // },
        getProducts() {
            this.$apollo
                .query({
                    query: Products,
                    fetchPolicy: 'no-cache',
                    variables: {
                        ...this.productVariables,
                    },
                })
                .then(response => {
                    // this.total_count =
                    //     response.data.filterProducts.paginatorInfo.total;
                    this.total_count = this.isNewArrival
                        ? this.category_filter
                            ? 30
                            : 120
                        : response.data.filterProducts.paginatorInfo.total;
                    this.filtered_count = this.total_count;
                    this.current_count =
                        response.data.filterProducts.paginatorInfo.count;
                    this.hasMorePages =
                        response.data.filterProducts.paginatorInfo.hasMorePages;
                    this.current_page = 1;

                    this.$store.commit(
                        'product/ADD_PRODUCTS',
                        response.data.filterProducts,
                    );
                    const products = response.data.filterProducts.data;
                    // console.log(products);
                    products.map(product => {
                        const checkStock = product.productvariants.every(
                            variant => {
                                return variant.total_in_stock <= 0;
                            },
                        );
                        if (checkStock) {
                            this.soldOutedProducts.push(product.id);
                        }
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        // getNewArrivalProducts() {
        //     this.$apollo
        //         .query({
        //             query: NewArrivalProducts,
        //             fetchPolicy: 'no-cache',
        //             variables: {
        //                 first: 10,
        //             },
        //         })
        //         .then(response => {
        //             this.total_count =
        //                 response.data.newArrivalProducts.paginatorInfo.total;
        //             this.filtered_count = this.total_count;
        //             this.current_count =
        //                 response.data.newArrivalProducts.paginatorInfo.count;
        //             this.hasMorePages =
        //                 response.data.newArrivalProducts.paginatorInfo.hasMorePages;
        //             this.current_page = 1;
        //             this.$store.commit(
        //                 'product/ADD_PRODUCTS',
        //                 response.data.newArrivalProducts,
        //             );
        //             // console.log(response.data);
        //             const products = response.data.newArrivalProducts.data;
        //             products.map(product => {
        //                 const checkStock = product.productvariants.every(
        //                     variant => {
        //                         return variant.total_in_stock == 0;
        //                     },
        //                 );
        //                 if (checkStock) {
        //                     this.soldOutedProducts.push(product.id);
        //                 }
        //             });
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // },
        // getTrendingProducts() {
        //     this.$apollo
        //         .query({
        //             query: TrendingProducts,
        //             fetchPolicy: 'no-cache',
        //             variables: {
        //                 first: 10,
        //             },
        //         })
        //         .then(response => {
        //             this.total_count =
        //                 response.data.trendingProducts.paginatorInfo.total;
        //             this.filtered_count = this.total_count;
        //             this.current_count =
        //                 response.data.trendingProducts.paginatorInfo.count;
        //             this.hasMorePages =
        //                 response.data.trendingProducts.paginatorInfo.hasMorePages;
        //             this.current_page = 1;

        //             this.$store.commit(
        //                 'product/ADD_PRODUCTS',
        //                 response.data.trendingProducts,
        //             );
        //             // this.trending_products =
        //             //     response.data.trendingProducts.data;
        //             // this.trending_products.map(product => {
        //             //     const checkStock = product.productvariants.every(
        //             //         variant => {
        //             //             return variant.total_in_stock == 0;
        //             //         },
        //             //     );
        //             //     if (checkStock) {
        //             //         this.soldOutedProducts.push(product.id);
        //             //     }
        //             // });
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         });
        // },
        toDetailPage(slug) {
            this.$router.push({
                // name: 'ProductDetail',
                name: 'ProductVariantDetail',
                params: {
                    slug: slug,
                },
            });
        },
        showMore() {
            this.current_page++;
            this.$apollo
                .query({
                    query: Products,
                    variables: {
                        ...this.productVariables,
                        page: this.current_page,
                    },
                })
                .then(response => {
                    this.current_count =
                        this.current_count +
                        response.data.filterProducts.paginatorInfo.count;
                    // this.hasMorePages =
                    //     response.data.filterProducts.paginatorInfo.hasMorePages;

                    this.hasMorePages =
                        this.isNewArrival &&
                        this.current_count >= this.total_count
                            ? false
                            : response.data.filterProducts.paginatorInfo
                                  .hasMorePages;

                    const products = response.data.filterProducts.data;

                    products.map(product => {
                        this.$store.commit('product/APPEND_PRODUCTS', product);
                        const checkStock = product.productvariants.every(
                            variant => {
                                return variant.total_in_stock == 0;
                            },
                        );
                        if (checkStock) {
                            this.soldOutedProducts.push(product.id);
                        }
                    });
                })
                .catch(error => {
                    console.log(error.response);
                });
        },
        startTransitionModal() {
            this.$refs.backdrop.classList.toggle('d-block');
            this.$refs.modal.classList.toggle('d-block');
        },
        endTransitionModal() {
            this.$refs.backdrop.classList.toggle('show');
            this.$refs.modal.classList.toggle('show');
        },
        toggle() {
            this.dropdownVisible = !this.dropdownVisible;
        },
        select(option) {
            this.selectedSubCat = option.name;
            if (!this.$route.query.name) {
                this.sub_category_filter = option.id;
            } else {
                this.category_filter = option.id;
            }
        },
        // prevMenu() {
        //     this.prevCategory = this.menuLinks[this.menuIndex];
        //     this.menuIndex -= 1;
        //     if (this.menuLinks.length === this.menuIndex) {
        //         this.menuIndex = 0;
        //     }
        //     this.activeFilter(this.prevCategory.id);
        //     if (!this.$route.query.name) {
        //         this.sub_category_filter = this.prevCategory.id;
        //     } else {
        //         this.category_filter = this.prevCategory.id;
        //     }
        // },
        // nextMenu() {
        //     this.nextCategory = this.menuLinks[this.menuIndex];
        //     this.menuIndex += 1;
        //     if (this.menuLinks.length === this.menuIndex) {
        //         this.menuIndex = 0;
        //     }
        //     this.activeFilter(this.nextCategory.id);
        //     if (!this.$route.query.name) {
        //         this.sub_category_filter = this.nextCategory.id;
        //     } else {
        //         this.category_filter = this.nextCategory.id;
        //     }
        // },
    },
};
</script>

<style lang="scss" scoped>
@import '@/sass/components/button.scss';
@import '@/sass/components/checkbox.scss';
.form-check-input {
    vertical-align: 0;
}
.form-check-input:checked[type='radio'] {
    background-image: none;
}
.sub-list-style {
    margin: 0;
    cursor: pointer;
    padding: 0;
    font-size: 30px;
    letter-spacing: -0.58px;
    color: #111111;
    max-width: 67vw;
}
.card {
    width: 10rem;
    // gap: 1rem;
    transition: all 0.2s ease-in-out !important;

    &:hover {
        transform: translateY(-5px);
        box-shadow: none;
        opacity: 0.9;
    }
    .icon {
        cursor: pointer;
        color: rgb(220, 217, 217);
        padding: 6px;
        width: 1.8rem;
        height: 1.8rem;
        top: 10px;
        right: 0px;
    }
    .wish-list {
        color: #77c306;
    }
    img {
        height: 10rem;
    }
    .arrival-body {
        cursor: pointer;
        padding: 0;
        margin: 0;
    }
    .sold-out {
        color: #fff;
        font-size: 12px;
        margin: 0;
        padding-top: 2px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 50px;
        background: transparent
            linear-gradient(102deg, #e43225 0%, #ff5400 100%) 0% 0% no-repeat
            padding-box;
    }
}
.active-category {
    position: relative;
    transition: color 1s;
    color: #77c306;
    &:after {
        content: '';
        display: block;
        margin: 0 auto;
        width: 2.375rem;
        padding-top: 0px;
        border-bottom: 0.3rem solid #77c306;
    }
}
.webFilterBox {
    display: block;
    transition: opacity 0.15s linear;
    background: #0000006e;

    @media (max-width: 767.98px) {
        display: none;
    }
}
.sub-title {
    font-size: 1.3rem;
}
@media (min-width: 768px) {
    .sub-title {
        font-size: 1.6rem;
    }
}
.title-center {
    font-size: calc(100% + 1vw + 1vh);
    color: #333;
    text-align: center;
    margin-top: 1rem;
}
@media (min-width: 576px) {
    .card {
        width: 15rem;
        img {
            height: 15rem;
        }
    }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 992px) {
    .title-center {
        color: #fff;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
// Large devices (desktops, 992px and up)
// @media (min-width: 992px) { ... }
.filterType {
    max-height: 143px;
    overflow-y: scroll;
    margin-bottom: 20px;
}
.aselect {
    // width: 280px;
    margin: 20px auto;
    .selector {
        border: 1px solid gainsboro;
        // background: #f8f8f8;
        position: relative;
        border-radius: 8px;
        z-index: 1;
        .arrow {
            position: absolute;
            right: 10px;
            top: 40%;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 10px solid #888;
            transform: rotateZ(0deg) translateY(0px);
            transition-duration: 0.3s;
            transition-timing-function: cubic-bezier(0.59, 1.39, 0.37, 1.01);
        }
        .expanded {
            transform: rotateZ(180deg) translateY(2px);
        }
        .label {
            display: block;
            padding: 12px;
            font-size: 16px;
            color: #888;
        }
    }
    ul {
        width: 100%;
        list-style-type: none;
        padding: 0;
        margin: 0;
        font-size: 16px;
        border: 1px solid gainsboro;
        position: absolute;
        z-index: 1;
        background: #fff;
    }
    li {
        padding: 12px;
        color: #666;
        input[type='radio'] {
            -webkit-appearance: none;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            outline: none;
            border: 3px solid gray;
        }

        input[type='radio']:before {
            content: '';
            display: block;
            width: 60%;
            height: 60%;
            margin: 20% auto;
            border-radius: 50%;
        }

        input[type='radio']:checked:before {
            background: green;
        }
        input[type='radio']:checked {
            border-color: green;
        }

        &:hover {
            // color: white;
            // background: seagreen;
            background: #f8f8f8;
        }
    }
    .current {
        background: #eaeaea;
    }
    .hidden {
        visibility: hidden;
    }
    .visible {
        visibility: visible;
    }
}
.horizontal >>> .v-hl-btn-prev svg {
    background: red;
    color: white;
    border-radius: 0;
    bottom: 7px;
}

.horizontal >>> .v-hl-btn-next {
    top: 0;
    bottom: 7px;
    transform: translateX(0);
}

.replaced-btn {
    height: 100%;
    background: linear-gradient(to right, #ffffff00, white);
    padding-left: 48px;
    display: flex;
    align-items: center;
}

.replaced-btn > div {
    font-weight: 700;
    font-size: 15px;
    line-height: 1;
    color: black;
    padding: 8px 12px;
    background: white;
    border-radius: 3px;
    border: 1px solid black;
}
</style>

<template>
    <div>
        <product-list />
        <shop-location />
        <footer-section />
    </div>
</template>

<script>
import ShopLocation from '../../components/home/ShopLocation.vue';
import Footer from '../../components/home/Footer';
import List from '../../components/products/List.vue';
export default {
    components: {
        'product-list': List,
        'shop-location': ShopLocation,
        'footer-section': Footer,
    },
};
</script>
